<template>
	<div class="content px-2">
		<!-- <div class="adsense-sm" v-if="!user.premium">
			<ins
				class="adsbygoogle"
				style="display:block"
				data-ad-client="ca-pub-8624981509801185"
				data-ad-slot="f08c47fec0942fa0"
				data-ad-format="auto"
			></ins>
		</div> -->
		<!-- <Adsense v-if="!user.premium" data-ad-client="ca-pub-8624981509801185" data-ad-slot="f08c47fec0942fa0"></Adsense> -->
		<!-- <div v-else style="height: 20px;"></div> -->
		<transition>
			<router-view />
		</transition>
		<!-- <Adsense v-if="!user.premium" data-ad-client="ca-pub-8624981509801185" data-ad-slot="f08c47fec0942fa0"></Adsense> -->
		<!-- <div style="height: 40px;" v-else></div> -->
		<!-- <div class="adsense" v-if="!user.premium">
			<ins
				class="adsbygoogle"
				style="display:block"
				data-ad-client="ca-pub-8624981509801185"
				data-ad-slot="f08c47fec0942fa0"
				data-ad-format="auto"
			></ins>
		</div> -->
	</div>
</template>
<script>
// import AdsenseBig from './partials/AdsenseBig'

import { mapGetters } from 'vuex'

export default {
	// components: {
	// 	AdsenseBig
	// },
	mounted() {
		this.updateLang()
		// if (!this.user.premium) {
		// 	;(adsbygoogle = window.adsbygoogle || []).push({})
		// }
	},
	computed: {
		...mapGetters(['user']),
	},
}
</script>
